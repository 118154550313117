import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueScrollactive from 'vue-scrollactive';

import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import locales from '@/translations';

import VueMeta from 'vue-meta';

import VModal from 'vue-js-modal';

import 'normalize.css';
import '@/styles/main.scss';

Vue.use(VueScrollactive);

Vue.use(VueI18Next);

Vue.use(VueMeta);

Vue.use(VModal);

i18next.init({
  lng: 'fr',
  fallbackLng: 'fr',
  whitelist: ['fr'],
  resources: {
    fr: { translation: locales.fr },
  },
});

const i18n = new VueI18Next(i18next);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
