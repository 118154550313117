<template>
  <section
    :id="$t('c.the_house.anchor_name')"
    class="flex-grid"
  >
    <div class="col wrapper-img-cover">
      <img
        src="../assets/the-house.jpg"
        :alt="$t('c.the_house.alt_img')"
      >
    </div>
    <div class="col section-green-armerie">
      <div class="section-content">
        <h2 class="section-title">
          {{ $t('c.the_house.title') }}
        </h2>
        <h3>{{ $t('c.the_house.subtitle') }}</h3>
        <p v-html="$t('c.the_house.text1')" />
        <p v-html="$t('c.the_house.text2')" />
        <p>{{ $t('c.the_house.text3') }} </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheHouse',
};
</script>

<style scoped lang="scss">

.wrapper-img-cover {
  img {
    object-position: center;
  }
}

</style>
