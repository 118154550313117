/* eslint-disable max-len */
export default {
  p: {
    // pages translation
    home: {
      meta: {
        title: "Chambres d'hôtes Gaïnaa | Arudy - Vallée d'Ossau - Pyrénées",
        description:
          "Gaïnaa, 3 spacieuses chambres d'hôtes dans une maison de maître au cœur d'Arudy, porte de la vallée d'Ossau et du parc national des Pyrénées.",
        image_for_media: 'https://gainaa.com/gainaa-logo-social-media.png',
        keywords: "Chambres d'hôtes, Arudy, Vallée d'Ossau, Pyrénées",
      },
    },
  },
  c: {
    // components translations
    navigation: {
      house: 'Village et maison de charme',
      bedrooms: 'Chambres d’hôtes et petit-déjeuner',
      ossau_valley: "Découvrir la vallée d'ossau",
      book: 'Réserver une chambre',
      covid19: 'Covid-19 mesures sanitaires',
    },
    first_loading: {
      alt_logo: "Chambres d'hôtes Gaïnaa | Arudy, Vallée d'Ossau, Pyrénées",
    },
    welcome: {
      title: "Bienvenue dans notre maison d'hôtes",
    },
    the_house: {
      anchor_name: 'village-et-maison-de-charme',
      title: "Maison d'hôtes de charme",
      subtitle:
        "Pour prendre le temps de vivre au cœur d'un village des Pyrénées",
      text1:
        "Gaïnaa est une belle bâtisse dont l'histoire est gravée dans le marbre, celui d'Arudy. Le temps de votre séjour dans nos chambres d'hôtes, <strong>goûtez aux plaisirs de la vie de village</strong> dans notre maison de maître au centre historique d'Arudy.",
      text2:
        "Partez à pied dans les ruelles à la découverte du village : les belles façades des maisons, les deux lavoirs, la chapelle sur la colline St-Michel... et le très joli <strong>musée d'Ossau</strong> à quelques pas.",
      text3:
        'L’été, la piscine offre un espace priviliégé de bien-être et de plaisir. Gaïnaa est le camp de base idéal pour vos balades : repos, confort et charme.',
      alt_img: "Façade de la maison d'hôtes Gaïnaa",
    },
    ossau_valley: {
      anchor_name: 'decouvrir-la-vallee-d-ossau',
      title: "La vallée d'ossau",
      subtitle: 'Sport, culture, nature et Parc national des Pyrénées !',
      text1:
        "La vallée d'Ossau est une vallée préservée où se mêlent <strong>villages authentiques, lacs de montagnes, cols mythiques</strong>, granges et troupeaux. En toile de fond, la silhouette unique du pic du midi d'Ossau, ancien volcan, dans le parc national des Pyrénées.",
      text2:
        "On vient dans la vallée d'Ossau pour profiter de la nature, faire du sport*, découvrir, se ressourcer, se reposer. Bref, pour profiter du temps qui s'offre à vous avec tous ces petits plaisirs entre vins du Jurançon et fromage pur brebis des estives...",
      text3:
        '* Randonnée, trail, canyoning, eau vive, canoë, escalade, VTT, via ferrata, tyrolienne, spéléologie, pêche...',
      text4:
        "À pied ou en voiture, des restaurants sont à proximité de notre maison d'hôtes Gaïnaa.",
      list1: {
        item1: "Cols d'Aubisque et de Marie-Blanque",
        item2: 'Col frontalier du Pourtalet, vers la vallée de Tena (Aragon)',
        item3: "Lacs de Bious-Artigues, d'Ayous, d'Anglas...",
        item4: 'Le plateau du Bénou',
      },
      list2: {
        item1: "Le Train d'Artouste",
        item2: "L'espace naturel / base de loisirs de Castet",
        item3: 'La Falaise aux vautours',
        item4: 'Un parc de 11 tyroliennes dans les gorges du Hourat',
      },
      list3: {
        item1: 'Pau, cité royale, capitale du Béarn',
        item2: 'Aas, le village des siffleurs',
        item3: 'Eaux-Chaudes et Eaux-Bonnes: villes thermales',
        item4:
          "Les stations de sports d'hiver et d'été de Gourette et d'Artouste",
      },
      list4: {
        item1: "Le musée d'Ossau",
        item2: 'Villa Bedat à Oloron-Sainte-Marie',
        item3:
          "L'Été ossalois : patrimoine, musique, art, nature, spectacle vivant...",
      },
      text5:
        "La vallée d'Ossau est labellisée Pays d'Art et d'Histoire des Pyrénées béarnaises.",
      text6:
        "Arudy est à la croisée des chemins. Au nord, <strong>Pau et son château</strong> avec la fameuse promenade du boulevard des Pyrénées, sont à 25 km. Vers l'ouest, vous plongez dans une autre vallée des Pyrénées béarnaises: la <strong>vallée d'Aspe</strong>, toujours dans le Parc National des Pyrénées. Vers l'est, vous rejoignez la <strong>vallée de l'Ouzom</strong> pour déboucher dans le val d'Azun au col du Soulor.",
      alt_img: {
        man_in_ossau_valley: "Homme marchant dans la vallée d'Ossau",
        train_artouste: "Le train d'Artouste roulant au bord de la falaise",
        via_ferrata:
          "Une personne montant une échelle d'une via ferrata de la vallée d'Ossau",
        patou: 'Un patou surveillant son troupeau de mouton',
        lake: "Un des magnifiques lacs de la vallée d'Ossau",
        pyrenees:
          "Les Pyrénées vu d'un sentier de randonnée de la vallée d'Ossau",
      },
    },
    footer: {
      legal_notice: 'Mentions légales',
    },
    covid19: {
      anchor_name: 'covid-19-mesures-sanitaires',
      title: 'Pour un séjour serein à gaïnaa',
      subtitle:
        'Si votre bien-être reste une priorité, le protocole sanitaire mis en place à Gaïnaa garantit également votre sécurité et la nôtre !',
      text1:
        "Pour vous garantir des séjours en toute sécurité dans des logements nettoyés et désinfectés, nous respectons rigoureusement l'ensemble des mesures d'hygiène préconisées par les autorités dans le cadre de l'épidémie.",
      text2:
        "<strong>Notre sens de l'accueil et notre goût pour la convivialité sont intacts</strong>, en respectant strictement les règles de distanciation sociale.",
      text3:
        'Voici un résumé des grands principes du protocole sanitaire mis en œuvre :',
      list: {
        item1: 'Aération des chambres et de toutes les pièces,',
        item2:
          'Nettoyage et désinfection des surfaces. Une attention particulière est portée aux éléments fréquemment touchés : poignées de porte, interrupteurs, télécommandes, rampes...,',
        item3: 'Lavage du linge de maison à température élevée,',
        item4: 'Lavage de la vaisselle à température élevée,',
        item5:
          "Du gel hydroalcoolique est mis à votre disposition au rez-de-chaussée et à l'étage,",
        item6:
          "Pour préparer et servir les petits-déjeuners, votre hôte est équipé d'un masque.",
      },
    },
    book: {
      anchor_name: 'reserver-une-chambre',
      title: 'Réserver une chambre à Gaïnaa',
      subtitle: "Nous sommes ouverts toute l'année !",
      text1: "Pour plus d'informations ou pour réserver contactez-nous",
      phone: '06 63 93 04 55 ',
      mail: 'monic@gainaa.com',
      address: '1 rue Hondaa 64260 Arudy',
      arrived_time: "<strong>Heure d'arrivée :</strong> entre 17h - 20h",
      leaved_time: '<strong>Heure maximale de départ :</strong> 11h00',
      payment: '<strong>Paiement :</strong> chèque, espèces',
      info_sup:
        "Possibilité de privatiser nos 3 chambres et le salon à l'étage",
      alt_img:
        "Carte situant la maison Gaïnaa dans la vallée d'Ossau ainsi que les principaux points d'intérêts",
      booking_now: 'Réserver maintenant !',
      alt_img_recommendation: 'Logo Lonely Planet',
    },
    bedrooms: {
      anchor_name: 'chambres-d-hotes-et-petit-dejeuner',
      title: "Chambres d'hôtes Gaïnaa",
      subtitle: 'Nuit et petit-déjeuner...',
      text1:
        "À l'étage, <strong>3 chambres d'hôtes</strong> spacieuses et raffinées aux ambiances différentes pour se lover en toute sérénité le temps d'une nuit ou plus et un grand salon cathédrale aux poutres remarquables. De la fenêtre, les montagnes vous appellent.",
      text2:
        'Nous portons une attention particulière à nos <strong>petits-déjeuners</strong> qui sont préparés par nos soins avec des produits locaux.',
      price: 'Tarif',
      breakfast_include: 'petit-déjeuner inclus',
      bedroom: 'chambre',
      person: 'personne',
      persons: 'personnes',
      possibility_separate_bed: 'Possibilité de lits séparés',
      asterix_taxes:
        "Taxe de séjour non incluse collectée pour la Communauté de communes de la vallée d'Ossau destinée à financer notamment des actions de protection et de gestion des espaces naturels à des fins touristiques : 0,90 € par personne majeure (18 ans et +) et par jour.",
      all_room_with_queen_size:
        "Toutes nos chambres sont équipées de lits Queen size (160 cm), de <strong>salle d'eau et toilettes indépendantes</strong>, de télévision par satellites et l'accès au Wifi est bien sûr gratuit.",
      baby_bed:
        'Nous pouvons mettre à votre disposition un lit bébé pour 10 euros.',
      park_car:
        'Stationnements publics à proximité dans un rayon  de cent mètres.',
      park_moto:
        "Un garage est disponible pour mettre à l'abri motos ou vélos.",
      alt_img: {
        bedroom: "Lit double avec mur floral d'une des chambres",
        breakfast:
          'Petit-déjeuner dans le salon principal préparé avec des produits locaux',
        pool: 'Piscine',
      },
    },
  },
};
