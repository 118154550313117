<template>
  <section :id="$t('c.ossau_valley.anchor_name')">
    <div class="flex-grid">
      <div class="col ossau-pics">
        <div class="wrapper-img-cover">
          <img
            src="../assets/ossau-valley-1.jpg"
            :alt="$t('c.ossau_valley.alt_img.man_in_ossau_valley')"
          >
        </div>
        <div class="wrapper-img-cover">
          <img
            src="../assets/ossau-valley-2.jpg"
            :alt="$t('c.ossau_valley.alt_img.train_artouste')"
          >
        </div>
        <div class="flex-grid">
          <div class="col wrapper-img-cover">
            <img
              src="../assets/ossau-valley-3.jpg"
              :alt="$t('c.ossau_valley.alt_img.via_ferrata')"
            >
          </div>
          <div class="col wrapper-img-cover">
            <img
              src="../assets/ossau-valley-4.jpg"
              :alt="$t('c.ossau_valley.alt_img.patou')"
              class="position-left-bottom"
            >
          </div>
        </div>
      </div>
      <div class="col section-green-armerie">
        <div class="section-content">
          <h2 class="section-title">
            {{ $t('c.ossau_valley.title') }}
          </h2>
          <h3>{{ $t('c.ossau_valley.subtitle') }}</h3>
          <p v-html="$t('c.ossau_valley.text1')" />
          <p class="green-light font-sm mt-40">
            {{ $t('c.ossau_valley.text2') }}
          </p>
          <p class="asterix green-light">
            {{ $t('c.ossau_valley.text3') }}
          </p>
          <p class="green-light font-sm bold mr-bt-50">
            {{ $t('c.ossau_valley.text4') }}
          </p>
          <ul>
            <li>{{ $t('c.ossau_valley.list1.item1') }}</li>
            <li>{{ $t('c.ossau_valley.list1.item2') }}</li>
            <li>{{ $t('c.ossau_valley.list1.item3') }}</li>
            <li>{{ $t('c.ossau_valley.list1.item4') }}</li>
          </ul>
          <ul>
            <li>{{ $t('c.ossau_valley.list2.item1') }}</li>
            <li>{{ $t('c.ossau_valley.list2.item2') }}</li>
            <li>{{ $t('c.ossau_valley.list2.item3') }}</li>
            <li>{{ $t('c.ossau_valley.list2.item4') }}</li>
          </ul>
          <ul>
            <li>{{ $t('c.ossau_valley.list3.item1') }}</li>
            <li>{{ $t('c.ossau_valley.list3.item2') }}</li>
            <li>{{ $t('c.ossau_valley.list3.item3') }}</li>
            <li>{{ $t('c.ossau_valley.list3.item4') }}</li>
          </ul>
          <ul>
            <li>{{ $t('c.ossau_valley.list4.item1') }}</li>
            <li>{{ $t('c.ossau_valley.list4.item2') }}</li>
            <li>{{ $t('c.ossau_valley.list4.item3') }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex-grid">
      <div class="col wrapper-img-cover display-bk">
        <img
          src="../assets/ossau-valley-5.jpg"
          :alt="$t('c.ossau_valley.alt_img.lake')"
        >
      </div>
      <div class="col">
        <div class="section-green-armerie-darker section-content pad-bt-10">
          <p>{{ $t('c.ossau_valley.text5') }}</p>
        </div>
        <div class="section-green-armerie section-content">
          <p v-html="$t('c.ossau_valley.text6')" />
        </div>
      </div>
    </div>
    <div class="image-full wrapper-img-cover">
      <img
        src="../assets/ossau-valley-6.jpg"
        :alt="$t('c.ossau_valley.alt_img.pyrenees')"
      >
    </div>
  </section>
</template>

<script>
export default {
  name: 'OssauValley',
};
</script>

<style scoped lang="scss">

.wrapper-img-cover {
  img {
    object-position: center;
  }

  .position-left-bottom {
    object-position: left bottom;
  }
}

.image-full {
  height: 400px;
}

.ossau-pics > div {
  height: 33.333333333%;
}

ul {
  padding: 0;

  li {
    position: relative;
    list-style: none;
    font-weight: 400;
    font-size: 17px;
    margin: 5px 0;

    &::before {
      position: absolute;
      margin-right: 10px;
      font-size: 20px;
      content: "\25a0";
      color: $green-padouk;
      top: -3px;
      left: -19px;
    }
  }
}

.green-light {
  color: $green-padouk-ll;
}
.mt-40 {
  margin-top: 40px;
}
.font-sm {
  font-size: 16px;
}
.asterix {
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  margin: 30px 0;
}

.bold {
  font-weight: 700;
}

.mr-bt-50 {
  margin-bottom: 50px;
}
</style>
