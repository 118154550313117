<template>
  <header :class="{ open: isOpen }">
    <div class="header-content">
      <div class="logo">
        <a
          href="#"
          @click="closeMenu()"
        >
          <img
            src="../assets/logo-gainaa.svg"
            alt="Gaïnaa"
          >
        </a>
      </div>

      <scrollactive
        class="nav"
        active-class="active"
        :offset="scrollActiveOffset"
      >
        <ul>
          <li
            v-for="(item, index) in items_nav"
            :key="index"
          >
            <a
              :href="item.anchor"
              class="scrollactive-item"
              @click="closeMenu()"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>
      </scrollactive>
    </div>
    <button
      class="hamburger hamburger--slider menu-button"
      type="button"
      aria-label="Menu"
      aria-controls="navigation"
      :class="{'is-active': isOpen}"
      @click="toggleMenu()"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner" />
      </span>
    </button>
  </header>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      items_nav: [{
        name: this.$t('c.navigation.house'),
        anchor: `#${this.$t('c.the_house.anchor_name')}`,
      }, {
        name: this.$t('c.navigation.bedrooms'),
        anchor: `#${this.$t('c.bedrooms.anchor_name')}`,
      }, {
        name: this.$t('c.navigation.ossau_valley'),
        anchor: `#${this.$t('c.ossau_valley.anchor_name')}`,
      }, {
        name: this.$t('c.navigation.book'),
        anchor: `#${this.$t('c.book.anchor_name')}`,
      }, {
        name: this.$t('c.navigation.covid19'),
        anchor: `#${this.$t('c.covid19.anchor_name')}`,
      }],
      isOpen: false,
      scrollActiveOffset: 70,
    };
  },
  mounted() {
    if (window.innerWidth <= 1000) {
      this.scrollActiveOffset = 0;
    }
  },
  methods: {
    closeMenu() {
      this.isOpen = false;

      if (window.innerWidth <= 1000) {
        // Allow scroll
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    },
    openMenu() {
      this.isOpen = true;

      // Disallow scroll
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
    },
    toggleMenu() {
      if (this.isOpen) {
        return this.closeMenu();
      }

      return this.openMenu();
    },
  },
};
</script>

<style scoped lang="scss">
header {
  position: sticky;
  top: 0;
  z-index: 2;

  .header-content {
    margin-top: -70px;
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
  }

  .logo {
    height: 45px;
    width: 45px;
    margin: 0 25px;
  }

  .nav {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    ul {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none;
      height: 100%;
      margin: 0 20px;

      a {
        height: 100%;

        display: flex;
        align-items: center;

        color: #000;
        font-family: $josefin-slab;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        font-size: 14px;

        &.active {
          font-weight: 700;
          color: $green-padouk;
        }
      }
    }
  }
}

.menu-button {
  display: none !important;
}

@media only screen and (max-width: 1000px) {
  header {
    position: relative;

    .header-content {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255, 0.9);
      margin: 0;
      flex-direction: column;
    }

    .logo {
      margin: 20px 0;
    }

    .nav {
      flex-direction: column;
      // Fix navigation on safari
      height: initial;

      ul {
        flex-direction: column;
        // Fix navigation on safari
        height: initial;
      }

      li {

        a {
          font-size: 18px;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &.open .header-content {
      display: flex;
    }
  }

  .menu-button {
    display: block !important;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 14;
  }
}
</style>
