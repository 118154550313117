<template>
  <modal
    name="amenitiz-booking-modal"
    width="80%"
    height="80%"
  >
    <div class="holds-the-iframe">
      <iframe
        name=""
        src="https://gainaa.amenitiz.io/fr/booking/room"
        scrolling="yes"
        height="100%"
        width="100%"
        FRAMEBORDER="no"
      />
    </div>
  </modal>
</template>

<script>
export default {
  name: 'AmenitizBookingModal',
};
</script>

<style scoped lang="scss">
  iframe {
    border: none;
    padding: 0;
    margin: 0;
  }

  .holds-the-iframe {
    width: 100%;
    height: 100%;
    background:url(../assets/loader.gif) center center no-repeat;
    background-size: 150px;
  }
</style>
