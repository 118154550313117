<template>
  <section :id="$t('c.bedrooms.anchor_name')">
    <div class="flex-grid">
      <div class="col section-green-padouk">
        <div class="section-content">
          <h2 class="section-title">
            {{ $t('c.bedrooms.title') }}
          </h2>
          <h3>{{ $t('c.bedrooms.subtitle') }}</h3>
          <p v-html="$t('c.bedrooms.text1')" />
          <p v-html="$t('c.bedrooms.text2')" />

          <div class="price-table">
            <h4>
              {{ $t('c.bedrooms.price') }}*
              <br />
              <span>{{ $t('c.bedrooms.breakfast_include') }}</span>
            </h4>

            <div class="grid-prices">
              <div class="grid-rows">
                <div class="bedrooms-number">
                  Chambre triple
                </div>
                <div class="persons-price">
                  <div>1 personne</div>
                  <div class="bs-ht">
                    <div class="prices">
                      <div>Basse saison</div>
                      <div>94 €</div>
                    </div>
                    <div class="prices">
                      <div>Haute saison</div>
                      <div>104 €</div>
                    </div>
                  </div>
                </div>
                <div class="persons-price">
                  <div>2 {{ $t('c.bedrooms.persons') }}</div>
                  <div class="bs-ht">
                    <div class="prices">
                      <div>Basse saison</div>
                      <div>112 €</div>
                    </div>
                    <div class="prices">
                      <div>Haute saison</div>
                      <div>122 €</div>
                    </div>
                  </div>
                </div>
                <div class="persons-price">
                  <div>3 {{ $t('c.bedrooms.persons') }}</div>
                  <div class="bs-ht">
                    <div class="prices">
                      <div>Basse saison</div>
                      <div>130 €</div>
                    </div>
                    <div class="prices">
                      <div>Haute saison</div>
                      <div>140 €</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-rows">
                <div class="bedrooms-number">
                  Chambre familiale
                </div>
                <div class="persons-price">
                  <div>1 personne</div>
                  <div class="bs-ht">
                    <div class="prices">
                      <div>Basse saison</div>
                      <div>94 €</div>
                    </div>
                    <div class="prices">
                      <div>Haute saison</div>
                      <div>104 €</div>
                    </div>
                  </div>
                </div>
                <div class="persons-price">
                  <div>2 {{ $t('c.bedrooms.persons') }}</div>
                  <div class="bs-ht">
                    <div class="prices">
                      <div>Basse saison</div>
                      <div>112 €</div>
                    </div>
                    <div class="prices">
                      <div>Haute saison</div>
                      <div>122 €</div>
                    </div>
                  </div>
                </div>
                <div class="persons-price">
                  <div>3 {{ $t('c.bedrooms.persons') }}</div>
                  <div class="bs-ht">
                    <div class="prices">
                      <div>Basse saison</div>
                      <div>130 €</div>
                    </div>
                    <div class="prices">
                      <div>Haute saison</div>
                      <div>140 €</div>
                    </div>
                  </div>
                </div>
                <div class="persons-price">
                  <div>4 {{ $t('c.bedrooms.persons') }}</div>
                  <div class="bs-ht">
                    <div class="prices">
                      <div>Basse saison</div>
                      <div>148 €</div>
                    </div>
                    <div class="prices">
                      <div>Haute saison</div>
                      <div>158 €</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-rows">
                <div class="bedrooms-number">
                  Chambre double
                </div>
                <div class="persons-price">
                  <div>1 personne</div>
                  <div class="bs-ht">
                    <div class="prices">
                      <div>Basse saison</div>
                      <div>94 €</div>
                    </div>
                    <div class="prices">
                      <div>Haute saison</div>
                      <div>104 €</div>
                    </div>
                  </div>
                </div>
                <div class="persons-price">
                  <div>2 {{ $t('c.bedrooms.persons') }}</div>
                  <div class="bs-ht">
                    <div class="prices">
                      <div>Basse saison</div>
                      <div>112 €</div>
                    </div>
                    <div class="prices">
                      <div>Haute saison</div>
                      <div>122 €</div>
                    </div>
                    <div class="separate-bed">
                      {{ $t('c.bedrooms.possibility_separate_bed') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="info-b-h-s">
              <span>Basse saison:</span> Octobre - Avril<br />
              <span>Haute saison:</span> Mai - Septembre
            </p>
            <p class="asterix">* {{ $t('c.bedrooms.asterix_taxes') }}</p>
          </div>
        </div>
      </div>
      <div class="col wrapper-img-cover display-bk">
        <img
          src="../assets/bk-bedroom.jpg"
          :alt="$t('c.bedrooms.alt_img.bedroom')"
        />
      </div>
    </div>
    <div class="flex-grid">
      <div class="col">
        <div class="col section-green-armerie-darker section-content pad-bt-10">
          <p v-html="$t('c.bedrooms.all_room_with_queen_size')" />
        </div>
        <div class="col section-green-padouk section-content pad-bt-10">
          <p class="square-tick">
            {{ $t('c.bedrooms.baby_bed') }}
          </p>
          <div class="flex-grid info-park">
            <div class="col">
              <p>{{ $t('c.bedrooms.park_car') }}</p>
            </div>
            <div class="col">
              <p>{{ $t('c.bedrooms.park_moto') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col flex-grid">
        <div class="col wrapper-img-cover display-bk">
          <img
            src="../assets/bk-breakfast.jpg"
            :alt="$t('c.bedrooms.alt_img.breakfast')"
          />
        </div>
        <div class="col wrapper-img-cover">
          <img
            src="../assets/bk-pool.png"
            :alt="$t('c.bedrooms.alt_img.pool')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Bedrooms',
};
</script>

<style scoped lang="scss">
.wrapper-img-cover {
  img {
    object-position: center;
  }
}

.price-table {
  margin-top: 50px;

  .bedrooms-number {
    font-weight: 700;
    position: relative;
    text-transform: capitalize;
    font-size: 18px;

    &::before {
      margin-right: 5px;
      font-size: 16px;
      content: '\25CF';
      color: #fff;
    }
  }
}

h4 {
  position: relative;
  font-family: $josefin-slab;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  margin: 35px 0;

  span {
    font-weight: 300;
  }

  &::after {
    position: absolute;
    content: '';
    width: 45px;
    height: 7px;
    bottom: -10px;
    left: 0;
    background-color: $green-padouk-ll;
  }
}

.asterix {
  font-style: italic;
  font-size: 15px;
  line-height: 19px;
  margin-top: 30px;
}

.square-tick {
  position: relative;

  &::before {
    margin-right: 5px;
    font-size: 20px;
    content: '\25a0';
    color: $green-padouk;
  }
}

.info-park {
  div:first-child p {
    padding-right: 0 20px;
    font-weight: 700;
  }

  div:last-child p {
    padding-left: 20px;
    font-weight: 700;
    color: $green-padouk;
  }
}

.grid-prices {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 35px;
}

@media (max-width: 730px) {
  .grid-prices {
    grid-template-columns: repeat(1, 1fr);

    .grid-rows {
      grid-template-rows: 45px repeat(4, auto);
      margin-bottom: 20px;
    }
  }

  .persons-price {
    width: 180px;
    margin: 0 auto;
  }
}

.persons-price {
  // display: grid;
  // grid-template-columns: 120px auto;
}

.grid-rows {
  display: grid;
  grid-template-rows: 35px repeat(4, 76px);
  min-width: 190px;
}

.bs-ht {
  margin-top: 7px;
  margin-bottom: 15px;
}

.prices {
  display: grid;
  grid-template-columns: 100px 50px;
  justify-content: end;

  div:first-child {
    font-size: 13px;
    font-style: italic;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.3);
    color: $green-padouk;
  }

  div:last-child {
    text-align: right;
  }
}

.separate-bed {
  font-style: italic;
  color: $green-padouk-ll;
  font-size: 15px;
  text-align: right;
  margin-top: 15px;
}

.info-b-h-s {
  span {
    color: $green-padouk;
  }

  font-size: 15px;
}
</style>
