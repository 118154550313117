<template>
  <div
    class="home-wrapper"
    :class="{ 'overflow-hidden': !loaded }"
  >
    <FirstLoading @loaded="allowScroll()" />
    <Welcome />
    <div>
      <Navigation />
      <TheHouse />
      <Bedrooms />
      <OssauValley />
      <Book />
      <Covid19 />
      <AmenitizBookingModal />
      <Footer />
    </div>
    <script type="application/ld+json">
      {{ JSON.stringify(jsonld) }}
    </script>
  </div>
</template>

<script>
import FirstLoading from '@/components/FirstLoading.vue';
import Welcome from '@/components/Welcome.vue';
import TheHouse from '@/components/TheHouse.vue';
import Bedrooms from '@/components/Bedrooms.vue';
import OssauValley from '@/components/OssauValley.vue';
import Book from '@/components/Book.vue';
import Covid19 from '@/components/Covid19.vue';
import Footer from '@/components/Footer.vue';
import Navigation from '@/components/Navigation.vue';
import AmenitizBookingModal from '../components/AmenitizBookingModal.vue';

export default {
  name: 'Home',
  components: {
    FirstLoading,
    Welcome,
    TheHouse,
    Bedrooms,
    OssauValley,
    Book,
    Covid19,
    Footer,
    Navigation,
    AmenitizBookingModal,
  },
  data() {
    return {
      loaded: false,
      jsonld: {
        // Generate with this -> https://microdatagenerator.org/localbusiness-microdata-generator/
        '@context': 'https://schema.org',
        '@type': 'BedAndBreakfast',
        name: 'Chambres d\'hôtes Gaïnaa',
        address: {
          '@type': 'PostalAddress',
          streetAddress: '1 rue Hondaa',
          addressLocality: 'Arudy',
          addressRegion: '',
          addressCountry: 'FR',
          postalCode: '64260',
        },
        image: 'https://gainaa.com/gainaa-logo-social-media.png',
        email: 'monic@gainaa.com',
        telephone: '+33 6 25 61 71 80',
        url: 'https://gainaa.com',
        paymentAccepted: ['cash', 'check'],
        openingHours: 'Mo,Tu,We,Th,Fr,Sa,Su 08:00-20:00',
        openingHoursSpecification: [{
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          opens: '08:00',
          closes: '20:00',
        }],
        geo: {
          '@type': 'GeoCoordinates',
          latitude: '43.106946',
          longitude: ' -0.428857',
        },
        priceRange: '$',
      },
    };
  },
  methods: {
    allowScroll() {
      this.loaded = true;
    },
  },
};
</script>

<style scoped lang="scss">

.home-wrapper {
  width: 100%;
  height: 100%;

  &.overflow-hidden {
    overflow: hidden;
  }
}

</style>
