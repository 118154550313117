<template>
  <section
    v-show="!loaded"
    class="loading-page"
  >
    <h1>
      <img
        :alt="$t('c.first_loading.alt_logo')"
        src="../assets/full-logo.svg"
      >
    </h1>
    <div class="lds-dual-ring" />
  </section>
</template>

<script>
export default {
  name: 'FirstLoading',
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    setTimeout(() => {
      document.getElementsByClassName('loading-page')[0].style.opacity = '0';
      this.$emit('loaded');

      setTimeout(() => {
        this.loaded = true;
      }, 800);
    }, 3000);
  },
};
</script>

<style scoped lang="scss">

section {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $green-armurerie;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  z-index: 3;

  transition: opacity .8s ease-in;

  h1 {
    width: 70%;
    max-width: 480px;

    img {
      width: 100%;
    }
  }
}
.lds-dual-ring {
  margin-top: 30px;
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  opacity: 0.2;
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
