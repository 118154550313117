<template>
  <section
    :id="$t('c.covid19.anchor_name')"
    class="section-content section-green-armerie"
  >
    <h2 class="section-title">
      {{ $t('c.covid19.title') }}
    </h2>
    <h3>{{ $t('c.covid19.subtitle') }}</h3>
    <div class="flex-grid">
      <div class="col">
        <p>{{ $t('c.covid19.text1') }}</p>
        <p v-html="$t('c.covid19.text2')" />
      </div>
      <div class="col">
        <p>{{ $t('c.covid19.text3') }}</p>
        <ul>
          <li>{{ $t('c.covid19.list.item1') }}</li>
          <li>{{ $t('c.covid19.list.item2') }}</li>
          <li>{{ $t('c.covid19.list.item3') }}</li>
          <li>{{ $t('c.covid19.list.item4') }}</li>
          <li>{{ $t('c.covid19.list.item5') }}</li>
          <li>{{ $t('c.covid19.list.item6') }}</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Covid19',
};
</script>

<style scoped lang="scss">

.flex-grid {
  div:first-child {
    margin-right: 40px;
    flex-grow: 1;
  }

  div:last-child {
    margin-left: 40px;
    flex-grow: 2;


    p {
      margin-bottom: 5px;
      font-weight: 700;
    }
  }

  ul {
    padding-left: 12px;
    margin: 0;

    li {
      font-size: 17px;
      padding: 5px 0;
    }
  }
}

@media only screen and (max-width: 1000px) {
 .flex-grid {
    div:first-child {
      margin-right: 0;
    }

    div:last-child {
      margin-left: 0;
    }
  }
}
</style>
