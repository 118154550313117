<template>
  <footer class="section-green-padouk">
    <a
      href="/mentions-legales.pdf"
      target="_blank"
    >{{ $t('c.footer.legal_notice') }}</a>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
footer {
  padding: 10px 0;
  text-align: center;
}
a {
  font-size: 15px;
  margin: 0;
  padding: 0;
  color: #fff;
  text-decoration: none;

  &:hover {
    font-weight: 700;
  }
}
</style>
