<template>
  <section :id="$t('c.book.anchor_name')" class="flex-grid">
    <div class="col section-green-padouk">
      <div class="section-content">
        <h2 class="section-title">
          {{ $t('c.book.title') }}
        </h2>
        <h3>{{ $t('c.book.subtitle') }}</h3>
        <div class="contact-us">
          {{ $t('c.book.text1') }}
        </div>
        <div class="contact-info">
          <p>{{ $t('c.book.phone') }}</p>
          <p>{{ $t('c.book.mail') }}</p>
        </div>
        <div class="address">
          <p>{{ $t('c.book.address') }}</p>
        </div>
        <div class="times">
          <p v-html="$t('c.book.arrived_time')" />
          <p v-html="$t('c.book.leaved_time')" />
        </div>
        <div class="payment">
          <p v-html="$t('c.book.payment')" />
        </div>
        <p>{{ $t('c.book.info_sup') }}</p>

        <div class="recommendation">
          <img
            src="../assets/lonelyPlanet_2024-25.jpg"
            :alt="$t('c.book.alt_img_recommendation')"
          />
        </div>

        <div class="wrapper-btn">
          <a class="btn-modal" @click="openModal">
            {{ $t('c.book.booking_now') }}
          </a>
        </div>
      </div>
    </div>
    <div class="col wrapper-img-cover display-bk">
      <img src="../assets/map.jpg" :alt="$t('c.book.alt_img')" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Book',
  methods: {
    openModal() {
      this.$modal.show('amenitiz-booking-modal');
    },
  },
};
</script>

<style scoped lang="scss">
.recommendation {
  img {
    width: 30%;
    border: 1px solid #ffff;
  }
}
.wrapper-img-cover {
  img {
    object-position: center;
  }
}

.contact-us {
  position: relative;
  font-family: $josefin-slab;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;

  &::after {
    position: absolute;
    content: '';
    width: 45px;
    height: 7px;
    bottom: -10px;
    left: 0;
    background-color: $green-padouk-ll;
  }
}

.times {
  margin: 50px 0 30px;

  p {
    margin: 5px 0;
  }
}

.payment {
  margin-bottom: 40px;
}

.times,
.payment {
  p {
    &::before {
      margin-right: 5px;
      font-size: 20px;
      content: '\25a0';
      color: $green-padouk;
    }
  }
}

.contact-info {
  background-color: #adbab6;
  margin: 20px 0;
  padding: 15px 0;
  position: relative;

  p {
    color: #000;
    margin: 8px 0;
    font-size: 25px;
    font-weight: 600;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 90px;
    background: #adbab6;
    left: -90px;
    top: 0;
  }
}

.address {
  p {
    font-weight: 600;
    font-size: 22px;
  }
}

.wrapper-btn {
  margin-top: 70px;
  text-align: center;
}

a.btn-modal {
  cursor: pointer;
  padding: 12px;
  border: 2px solid white;
  font-size: 20px;

  &:hover {
    border-color: $green-armurerie;
  }
}
</style>
