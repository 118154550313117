<template>
  <section>
    <div class="recommendation">
      <img
        src="../assets/lonelyPlanet_2024-25.jpg"
        :alt="$t('c.book.alt_img_recommendation')"
      />
    </div>
    <div class="title">
      <h2>
        <img src="../assets/logo-white.svg" alt="Gaïnaa" />
        <span>
          {{ $t('c.welcome.title') }}
        </span>
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Welcome',
};
</script>

<style scoped lang="scss">
section {
  width: 100%;
  height: 100%;

  background-image: url('../assets/welcome-bk.jpg');
  background-size: cover;
  background-position: center bottom;

  .recommendation {
    position: absolute;
    img {
      width: 15%;
      margin-left: 3%;
      margin-top: 3%;
    }
  }

  .title {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

h2 {
  padding: 0;
  margin: 0;

  font-family: $josefin-slab;
  width: 80%;
  max-width: 600px;

  span {
    position: relative;
    background-color: $green-armurerie;
    color: $green-padouk;
    text-transform: uppercase;
    display: block;
    font-size: 27px;

    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 1;
    padding: 10px 10px 6px 10px;
    font-weight: 700;

    &::before {
      position: absolute;
      content: '';
      height: 50px;
      width: 50px;
      top: -4px;
      left: -91px;
      background: url('../assets/arrow_green-armerie.svg') no-repeat;
    }
  }
}

.euros64 {
  position: absolute;
  bottom: 92px;
  right: 20px;
  width: 110px;
  font-size: 0;

  img {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .euros64 {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 70px;
  }

  h2 {
    span {
      &::before {
        background: none;
      }
    }
  }
}
</style>
