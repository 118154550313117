<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

const siteUrl = 'https://gainaa.com';

export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.$t('p.home.meta.title'),
      htmlAttrs: {
        lang: 'fr',
      },
      meta: [
        { name: 'description', content: this.$t('p.home.meta.description') },
        { name: 'keywords', content: this.$t('p.home.meta.keywords') },

        // Social media metas

        // OpenGraph data (Most widely used)
        { property: 'og:title', content: this.$t('p.home.meta.title') },
        { property: 'og:site_name', content: 'Gaïnaa' },
        { property: 'og:type', content: 'website' },
        // Should the the same as your canonical link, see below.
        { property: 'og:url', content: siteUrl },

        { property: 'og:image', content: this.$t('p.home.meta.image_for_media') },
        { property: 'og:description', content: this.$t('p.home.meta.description') },
        { property: 'og:locale', content: 'fr_FR' },

        // Twitter card
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: siteUrl },
        { name: 'twitter:title', content: this.$t('p.home.meta.title') },
        { name: 'twitter:description', content: this.$t('p.home.meta.description') },
        { name: 'twitter:image', content: this.$t('p.home.meta.image_for_media') },

        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('p.home.meta.title') },
        { itemprop: 'description', content: this.$t('p.home.meta.description') },
        { itemprop: 'image', content: this.$t('p.home.meta.image_for_media') },
      ],
    };
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
